// eslint-disable-next-line filenames/match-exported
import GlobalModal from 'components/modals/GlobalModal';
import React, { useEffect, createContext } from 'react';
import * as Sentry from '@sentry/react';
import {
  Redirect,
  Route,
  Switch,
  matchPath,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom';
import AppointmentResourcePage from 'routes/merchant-scoped-portal/integrations/appointments/resource/AppointmentsResourcePage';
import LoyaltyResourcePage from 'routes/merchant-scoped-portal/integrations/loyalty/resource/LoyaltyResourcePage';
import GlobalToast from 'components/GlobalToast';
import MerchantOverviewPage from './MerchantOverviewPage';
import PrivacyFooter from 'components/SqPrivacyFooter';
import { useSelector } from 'react-redux';
import TopNav from './layout-components/TopNav';
import MerchantLogo from './layout-components/MerchantLogo';
import { AppState } from 'store';
import { selectBoolFlag } from 'store/featureSlice';
import { AnonymousBoolFlag } from 'routes/profile/models/Flags';
import {
  MerchantPortalBaseData,
  useGetMerchantPortalBaseDataQuery,
} from 'store/query/api-extensions/merchantPortalBaseRpcs';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import ProfileFactory from 'routes/profile/factories/ProfileFactory';
import { ProfileConverters } from 'routes/profile/models/Profile';
import ModuleLoadFailed from 'routes/profile/common/errors/ModuleLoadFailed';
import { RequestStatus } from 'rpc/model/squareup/customers/request';
import PageNotFound from 'routes/merchant-scoped-portal/PageNotFound';
import { ErrorBoundary } from 'react-error-boundary';
import { logUnexpectedError } from 'routes/merchant-scoped-portal/utils/error';
import SomethingWentWrong from './components/alerts/SomethingWentWrong';
import { Team } from './teamRegistry';
import { buyerportalCdpClient } from 'services/tracking/cdp/clients/buyerportal';
import {
  FeatureFormat,
  FeatureID,
  EventName,
  ViewFeatureEvent,
  FeatureName,
  EncounterErrorEvent,
} from 'services/tracking/cdp/events/types';
import Cookies from 'js-cookie';
import GlobalLoader from 'routes/profile/common/loading/GlobalLoader';

const MOCKED_MERCHANT_OVERVIEW_BASE_DATA: MerchantPortalBaseData = {
  merchantOverview: {
    merchantName: 'Le Petit Name',
    merchantId: 'mock', // ensures that consumers of this data (via context) know we're in a mock context
    // bannerImage: 'https://via.placeholder.com/300x1400',
    // Dimensions for the below 2 come from this slack discussion:
    // https://square.slack.com/archives/C06E593M88N/p1714078690414709?thread_ts=1714072242.674639&cid=C06E593M88N
    // Either way, resizing is needed on the FE
    logoImage: 'https://via.placeholder.com/200x200',
    fullFormatLogo: 'https://via.placeholder.com/1280x648',
    mainUnitId: '1',
    businessDescription: "We're a small business that sells cool stuff.",
    websiteUrl: 'https://www.example.com',
    primaryBrandColor: '#A020F0',
  },
  // use the profile factory and the profile to buyer converter to create a buyer object
  buyer: ProfileConverters.toRpcBuyer(ProfileFactory.build()),
};

export interface MerchantPortalContextData {
  merchantPortalBaseData?: MerchantPortalBaseData;
  error?: Object;
  isLoading: boolean;
}

// Will hold context about both the:
// Current merchant's theme-ing data (i.e. images)
// User data (in the event the user is logged in)
// Providing it via context to avoid prop drilling to all the sub pages of the merchant portal
// TODO: Replace data type with the type from the query store
export const MerchantPortalContext = createContext<MerchantPortalContextData>({
  merchantPortalBaseData: undefined,
  error: undefined,
  isLoading: true,
});

type MerchantPortalParams = {
  merchantId: string;
};

const withErrorBoundary = (Component: React.FC, team: Team) => {
  const TeamErrorBoundary = (props: any) => (
    <ErrorBoundary
      fallback={
        <SomethingWentWrong
          type="page"
          onTryAgain={() => window.location.reload()}
        />
      }
      onError={(error) => logUnexpectedError(error, [team])}
    >
      <Component {...props} />
    </ErrorBoundary>
  );
  TeamErrorBoundary.displayName = `TeamErrorBoundary(${team})`;
  return TeamErrorBoundary;
};

const LoyaltyPageWithErrorBoundary = withErrorBoundary(
  () => <LoyaltyResourcePage />,
  Team.CeWeb
);

const MerchantPortal: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const areAnonymousFlagsLoaded = useSelector(
    (state: AppState) => state.feature.areAnonymousFlagsLoaded
  );

  const isMerchantPortalEnabled = useSelector((state: AppState) =>
    selectBoolFlag(state, AnonymousBoolFlag.enableMerchantPortal)
  );

  // A valid staging merchant id: MLSE87BN91XC6
  const { merchantId } = useParams<MerchantPortalParams>();
  const finalMerchantIdArg = merchantId === 'mock' ? skipToken : merchantId;
  // TODO: Convert to const after removing the 'mock' escape hatch
  let {
    data: merchantPortalBaseData,
    // eslint-disable-next-line prefer-const
    isLoading: isMerchantPortalBaseDataLoading,
    // eslint-disable-next-line prefer-const
    isUninitialized,
    // eslint-disable-next-line prefer-const
    error,
  } = useGetMerchantPortalBaseDataQuery(finalMerchantIdArg);

  if (isUninitialized) {
    merchantPortalBaseData = MOCKED_MERCHANT_OVERVIEW_BASE_DATA;
  }

  // Track the view event
  useEffect(() => {
    // Merchant overview data is required to populate 'additional parameters'
    if (!merchantPortalBaseData) {
      return;
    }
    if (merchantPortalBaseData.buyer) {
      buyerportalCdpClient.identify(merchantPortalBaseData.buyer.personToken!);
    } else {
      // identify unauth'd users with _savt
      const savt = Cookies.get('_savt');
      // _savt should be set at entry point, but just in case
      buyerportalCdpClient.identify(savt || 'anonymous');
    }

    const { buyer, merchantOverview } = merchantPortalBaseData;
    const viewMerchantPortalOverviewEvent: ViewFeatureEvent = {
      feature_name: FeatureName.MerchantOverviewPage,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.PAGE,
      event_description: 'view Profile seller page',
      is_default_view: true,
      feature_layout: {
        has_about_us: Boolean(merchantOverview?.businessDescription),
        has_logo: Boolean(
          merchantOverview?.logoImage || merchantOverview?.fullFormatLogo
        ),
        has_banner: false,
        has_site: Boolean(merchantOverview?.websiteUrl),
        // TODO: If user is auth'd, loyalty will need to push up data to the merchant portal context
        // after the loyalty tile is loaded
        // loyalty_count: num associated loyalty accounts
      },
      additional_parameters: {
        merchant_id: merchantOverview.merchantId,
        merchant_name: merchantOverview.merchantName,
        buyer_authenticated: Boolean(buyer),
      },
    };
    buyerportalCdpClient.track(
      EventName.VIEW_FEATURE,
      viewMerchantPortalOverviewEvent
    );
  }, [merchantPortalBaseData]);

  if (error) {
    // Note: When supplying a not-real merchant id, the backend returns BAD_REQUEST rather than NOT_FOUND
    if (error.status === RequestStatus.STATUS_BAD_REQUEST) {
      return <PageNotFound />;
    }
    // track error
    const overviewPageLoadErrorEvent: EncounterErrorEvent = {
      feature_name: FeatureName.MerchantOverviewPage,
      feature_id: FeatureID.MerchantOverviewPage,
      feature_format: FeatureFormat.PAGE,
      event_called: 'view Profile seller page',
      event_description: 'encountered error',
      error_type: 'load',
      error_message: `failed to load merchant portal data: ${
        error.message || 'unknown error'
      }`,
    };
    buyerportalCdpClient.track(
      EventName.ENCOUNTER_ERROR,
      overviewPageLoadErrorEvent
    );
    return <ModuleLoadFailed />;
  }

  if (!areAnonymousFlagsLoaded || isMerchantPortalBaseDataLoading) {
    return <GlobalLoader />;
  }

  if (!isMerchantPortalEnabled) {
    return <Redirect to="/" />;
  }

  if (!merchantPortalBaseData) {
    // This should never happen because loading is false and error is not set
    // But TS doesn't know that
    Sentry.captureException(
      'merchantPortalBaseData was undefined when it should not be.'
    );
    return <ModuleLoadFailed />;
  }

  const merchantName = merchantPortalBaseData.merchantOverview.merchantName;
  const merchantLogoImgUrl =
    merchantPortalBaseData.merchantOverview.fullFormatLogo ||
    merchantPortalBaseData.merchantOverview.logoImage ||
    undefined;

  const isOverviewPage = matchPath(location.pathname, {
    path: '/merchantportal/:merchantId',
    exact: true,
    strict: false,
  });

  return (
    // Already wrapped in a flex column by outer layout
    <>
      <MerchantPortalContext.Provider
        value={{
          merchantPortalBaseData,
          isLoading: isMerchantPortalBaseDataLoading,
          error,
        }}
      >
        <GlobalToast />
        <GlobalModal />
        <TopNav
          isAuthenticated={Boolean(merchantPortalBaseData.buyer)}
          buyerName={merchantPortalBaseData?.buyer?.name?.first}
        />
        <div className="flex align items-center flex-col gap-4 pt-4 pb-6">
          <MerchantLogo
            imgUrl={merchantLogoImgUrl}
            sellerBrandColor={
              merchantPortalBaseData.merchantOverview.primaryBrandColor
            }
            clickable={!isOverviewPage}
          />
          {merchantName && <h2 className="text-center m-0">{merchantName}</h2>}
        </div>
        <div className="grow w-full">
          <Switch>
            <Route
              exact
              path={path}
              component={withErrorBoundary(
                MerchantOverviewPage,
                Team.CustomerFoundations
              )}
            />
            <Route
              path={`${path}/loyalty`}
              component={LoyaltyPageWithErrorBoundary}
            />
            <Route
              path={`${path}/appointments`}
              component={withErrorBoundary(
                AppointmentResourcePage,
                Team.CustomerFoundations
              )}
            />
          </Switch>
        </div>
      </MerchantPortalContext.Provider>
      <PrivacyFooter />
    </>
  );
};

export default MerchantPortal;
